<template>
  <div>
    <p>This view file path: src/views/dashboard</p>
    <p>
      <span class="mr-1" target="_blank">Check</span>
      <a href="https://coreui.io/vue/docs">CoreUI Vue Documentation</a>
    </p>
    <p>{{ $t("main.welcome", { company: "Lokalise" }) }}</p>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>
